import React, { useEffect, useState } from "react";

import { css } from "@emotion/css";
import buttons from "../../../styles/buttons";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { desktop, laptop, mobile, tablet } from "../../../styles/breakpoints";
import { client } from "../../../lib/client";

const PreOrder = () => {
  const [productImage, setProductImage] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    client.fetch(
      `*[_type == "product"] | order(id asc){
          id,
          image{
            asset ->{
              _id,
              url
            }
          },
          gallery[]{
            asset ->{
                _id,
                url
              }
          },
          slug,
          title,
          subtitle,
          price,
          description,
          payLink,
      }`
      ).then((data) => {
        setProducts(data);
      }).catch(console.error);
  },[]);

  useEffect(() => {
    client.fetch(
      `*[_type == "productImage"] {
        location,
        image{
          asset ->{
            _id,
            url
          }
        }
      }`
    ).then((data) => {
      setProductImage(data);
    }).catch(console.error);
  },[])

  return (
    <div style={{ position: "relative", backgroundColor: '#f5f5f5' }}>
      <div
        className={style.bg}
        style={{ backgroundImage: `url(${productImage[0] && productImage.filter(item => item.location === "preorder background")[0].image.asset.url})` }}
      ></div>
      <div
        className={`${style.container}${
          window.location.pathname.includes("shop") ? " shop" : ""
        }`}
      >
        <h3>The new site is under construction. Please check back later. Thank you!</h3>
      </div>
    </div>
  );
}

const style = {
  bg: css({
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 100%",
    transition: "all .4s ease",
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundSize: "cover",
    left: 0,
    top: 0,

    "&:before": {
      background:
        "linear-gradient(to top, rgb(247 247 247 / 0%) 0%, rgb(245 245 245) 50%, rgb(245 245 245) 100%)",
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: 200,
    },
  }),
  container: css({
    display: "flex",
    paddingBottom: 100,
    paddingTop: 100,
    position: "relative",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    paddingLeft: 40,
    paddingRight: 40,

    "&.shop": {
      paddingTop: 184,

      h2: {
        marginBottom: 70,
      },
    },

    h2: {
      color: "#1B243A",
      textAlign: "center",
      fontFamily: "Halvar Breitschrift",
      fontSize: 100,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "105%",
      letterSpacing: "-4px",
      marginBottom: 170,
      background: "linear-gradient(108deg, #1B243A 17.98%, #95A1BE 105.95%)",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    ".swiper-wrapper": {
      display: "flex",
      margin: "auto",
    },
    ".swiper": {
      width: "100%",
      height: "100%",
    },
    ".wrapper": {
      maxWidth: 1240,
      width: "100%",
      display: "flex",
      gap: 30,

      "&.mobile": {
        display: "none",
      },

      ".product-wrapper": {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        gap: 40,
        width: 435,

        ".product": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: 40,
          background: "rgba(245, 245, 246, 0.50)",
          backdropFilter: "blur(10px)",
          height: "100%",
          paddingBottom: 50,

          img: {
            width: "100%",
            height: "100%",
            objectFit: "cover",
          },
          h4: {
            color: "#1B243A",
            textAlign: "center",
            fontFamily: "Halvar Breitschrift",
            fontSize: 20,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            // textTransform: "uppercase",
            marginBottom: 10,
            paddingLeft: 35,
            paddingRight: 35,
          },
          p: {
            color: "#6D7DA1",
            textAlign: "center",
            fontFamily: "Suisse Intl",
            fontSize: 18,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            marginBottom: 28,
            paddingLeft: 35,
            paddingRight: 35,
          },
          span: {
            padding: "8px 20px",
            alignItems: "center",
            borderRadius: 88.095,
            border: "0.881px solid #6D7DA1",
            color: "#6D7DA1",
            fontFamily: "Suisse Intl",
            fontSize: 17,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            // textTransform: "uppercase",
          },
        },
      },
    },
    [desktop]: {},
    [laptop]: {
      paddingLeft: 30,
      paddingRight: 30,

      h2: {
        textAlign: "left",
        fontSize: 75,
        letterSpacing: "-3px",
        marginBottom: 100,
        marginLeft: 60,
        width: "100%",
      },
      ".swiper-wrapper": {},
      ".wrapper": {
        "&.desktop": {
          display: "none",
        },
        "&.mobile": {
          display: "flex",
          gap: 0,
        },
        ".product-wrapper": {
          gap: 40,
          width: 490,
          ".product": {
            paddingBottom: 100,
          },
        },
      },
    },

    [tablet]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 80,
      paddingTop: 80,
      h2: {},
      ".wrapper": {
        ".product-wrapper": {
          gap: 30,
          width: 500,

          ".product": {
            h4: {
              fontSize: 18,
            },
            p: {
              fontSize: 16,
              paddingLeft: 20,
              paddingRight: 20,
            },
            span: {
              padding: "11.133px 25.048px",
              fontSize: 16,
            },
          },
        },
      },
    },

    [mobile]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 60,
      paddingTop: 60,

      h2: {
        fontSize: 46,
        letterSpacing: "-1.84px",
        marginLeft: 40,
      },
      ".swiper-wrapper": {},
      ".wrapper": {
        ".product-wrapper": {
          gap: 23,
          width: 330,
          ".product": {
            paddingBottom: 40,
            h4: {
              fontSize: 18,
              paddingLeft: 10,
              paddingRight: 10,
            },
            p: {
              fontSize: 16,
              paddingLeft: 10,
              paddingRight: 10,
            },
            span: {
              padding: "5px 12px",
              fontSize: 14,
            },
          },
        },
      },
    },
  }),
};

export default PreOrder;
